import { styled } from 'styled-components';
import { Link } from '@components/links/Link';
import { HeaderContainer, HeaderOuterContainer } from './Common';
import { HappyLogo } from '@components/HappyLogo';
import { Back } from '@hn-ui/icons';

const ScHeaderContainer = styled(HeaderContainer)`
  display: grid;
  grid-template-columns: 72px 1fr 72px;
  justify-content: unset;

  ${({ theme: { down } }) => down('tabletMd')} {
    justify-self: center;
    & > a {
      display: none;
    }
  }
`;

const Logo = styled(HappyLogo)`
  margin: 0 auto;
`;

const EmptyHeaderWithBack = () => (
  <HeaderOuterContainer>
    <ScHeaderContainer>
      <Link to=".." bold>
        <Back />
        Back
      </Link>
      <Logo />
    </ScHeaderContainer>
  </HeaderOuterContainer>
);

export { EmptyHeaderWithBack };
