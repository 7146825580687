import { redirectDocument } from '@remix-run/node';
import type { MetaFunction, LoaderFunctionArgs } from '@remix-run/node';
import { checkUserAuth } from '../utils/session.server';
import { AuthLayoutBase } from '@components/layouts/auth-layout';
import { GO_URL } from 'app/utils/urls.data';

export const meta: MetaFunction = ({ matches }) => {
  const parentMeta = matches
    .flatMap((match) => match.meta ?? [])
    .filter((meta) => !('title' in meta));

  return [
    ...parentMeta,
    {
      title: 'Sign in to Happy Numbers',
    },
  ];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const isAuth = await checkUserAuth(request);

  if (isAuth) throw redirectDocument(GO_URL);

  return null;
};

export default function AuthLayout() {
  return <AuthLayoutBase />;
}
