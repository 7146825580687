import { styled } from 'styled-components';
import {
  FooterContainer,
  FooterOuterContainer,
  PolicyLink,
  PolicyText,
  RightsText,
} from './Common';
import { publicUrls } from 'app/utils/urls.data';

const ScFooterContainer = styled(FooterContainer)`
  gap: 8px;
`;

const Container = styled.div`
  grid-column: 1/-1;
  text-align: center;
`;

const AuthFooter = ({ ...rest }) => (
  <FooterOuterContainer>
    <ScFooterContainer {...rest}>
      <Container>
        <RightsText>© 2025 Happy Numbers. All rights reserved</RightsText>
        <PolicyText>
          Use of this web site constitutes acceptance of the{' '}
          <PolicyLink href={publicUrls.privacyPolicy}>
            Privacy Policy
          </PolicyLink>{' '}
          and{' '}
          <PolicyLink href={publicUrls.termsOfService}>
            Terms of Service
          </PolicyLink>
          .
        </PolicyText>
      </Container>
    </ScFooterContainer>
  </FooterOuterContainer>
);

export { AuthFooter };
