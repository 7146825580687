import { Outlet } from '@remix-run/react';
import {
  Container,
  Content,
  FooterContainer,
  HeaderContainer,
  StickyContainer,
} from './Common';
import { EmptyHeaderWithBack } from '@components/headers/EmptyHeaderWithBack';
import { styled } from 'styled-components';
import { AuthFooter } from '@components/footers/AuthFooter';

const ScContainer = styled(Container)`
  padding-top: 80px;

  ${({ theme: { down } }) => down('phone')} {
    padding-top: 32px;
  }
`;

const ScContent = styled(Content)`
  margin: 0 auto;
`;

const ScFooterContainer = styled(FooterContainer)`
  & {
    border-top: none;
  }
`;

const AuthLayoutBase = () => {
  return (
    <>
      <StickyContainer>
        <HeaderContainer>
          <EmptyHeaderWithBack />
        </HeaderContainer>
      </StickyContainer>
      <ScContainer role="application">
        <ScContent>
          <Outlet />
        </ScContent>
      </ScContainer>
      <ScFooterContainer>
        <AuthFooter />
      </ScFooterContainer>
    </>
  );
};

export { AuthLayoutBase };
